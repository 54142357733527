import { EnvironmentName } from "./enum/environmentname.enum";
import { commonEnv } from "./environment.common";

//common environment에서 원하는 설정만 커스텀하는 구조.
const env: Partial<typeof commonEnv> = {
    environmentName: EnvironmentName.Development,
    base_url: 'http://localhost:3000',
    socket_url: 'ws://localhost:3000',

};

export const environment = {...commonEnv, ...env,}